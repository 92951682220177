<input
  type="text"
  class="inline-edit--field op-input"
  [opAutofocus]="shouldFocus"
  [attr.aria-required]="required"
  [attr.required]="required"
  [disabled]="inFlight"
  [(ngModel)]="value"
  (keydown)="handler.handleUserKeydown($event)"
  [id]="handler.htmlId"
/>
