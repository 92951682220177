<ng-template
  #optionTemplate
  let-item
  let-search="search"
>
  <op-principal
    *ngIf="item && item.href"
    [principal]="item"
    [hideName]="true"
    size="mini"
  ></op-principal>
  <span [opSearchHighlight]="search"
        class="op-user-autocompleter--name">
      {{ item.name }}
    </span>
  <span
    class="op-autocompleter__option-principal-email"
    *ngIf="item.email"
    [opSearchHighlight]="search"
  >{{ item.email }}</span>
</ng-template>
<ng-template #footerTemplate *ngIf="inviteUserToProject">
  <op-invite-user-button
    [projectId]="inviteUserToProject"
    class="op-select-footer"
  ></op-invite-user-button>
</ng-template>
